<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field dense label="Название:" v-model="editedItem.name" />
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-select
                dense
                label="Категория:"
                :items="categoryList"
                v-model="editedItem.category"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field dense label="Инфо:" v-model="editedItem.info" />
            </v-col>
            <v-col cols="12">
              <v-text-field dense label="SFTP название:" v-model="editedItem.sftp_name" />
            </v-col>
            <v-col cols="12">
              <v-text-field dense label="Краткое название (не более 4 символов):" v-model="editedItem.short_name" />
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                label="Тип:"
                :items="roomTypeList"
                v-model="editedItem.room_type"
              />
            </v-col>
            
            <v-col cols="6">
              <v-text-field dense v-if="editedItem.room_type == 'koika'" label="Количество коек:" v-model="editedItem.koika_count" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Отменить </v-btn>
        <v-btn color="primary" text @click="update" :disabled="disabledBtn">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import user from "../../../store/auth";
import toast from "../../../plugins/toast";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js"

export default {
  name: "Room",
  data() {
    return {
      disabledBtn: false,
      editedItem: {
        id: null,
        category: null,
        info: null,
        name: null,
        status: null,
        status_text: null,
      },
      categoryList: [],
      roomTypeList: [
        {
          text: 'Комната',
          value: 'room'
        },
        {
          text: 'Койка',
          value: 'koika'
        },
      ]
    };
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    },
  },
  async created() {
    if (this.$route.params.id) {
      let response = await CategoriesApiUrls.getRoom(this.$route.params.id)
      this.editedItem = response;
    }
    let response = await CategoriesApiUrls.getCategories()
    this.initializeCategory(response);
  },
  methods: {
    initializeCategory(data) {
      data.map((el) => {
        const obj = {
          text: el.name,
          value: el.id,
        };
        this.categoryList.push(obj);
      });
    },
    async update() {
      const request = {
        name: this.editedItem.name,
        info: this.editedItem.info,
        category: this.editedItem.category, 
        room_type: this.editedItem.room_type,
        sftp_name: this.editedItem.sftp_name
      };
      if(this.editedItem.room_type == 'koika') request.koika_count = this.editedItem.koika_count
      this.disabledBtn = true;
      if (this.$route.params.id) {
        try {
          await CategoriesApiUrls.setRoom(this.editedItem.id, request)
          toast.success("Успешно создано!")
          this.disabledBtn = false;
          this.close();
        }
        catch(e){
          this.disabledBtn = false;
        }
      } else {
        this.disabledBtn = true;
        request.status = "clean"
        try {
          await CategoriesApiUrls.createRoom(request)
          toast.success("Успешно создано!")
          this.disabledBtn = false;
          this.close();
        }
        catch(e){
          this.disabledBtn = false;
        }
      }
    },
    close() {
      this.$router.push("/rooms");
    },
  },
};
</script>

<style scoped></style>
